<section class="policie-detail">
  <div class="row w-100 m-0 policy-name-container">
    <div class="w-100 d-flex justify-content-between align-items-center">
      <h3>FAQ'S</h3>
    </div>
  </div>

  <div class="container-form pt-2">
    <div *ngFor="let item of faqs; index as i" [ngClass]="{'mb-2': i !== faqs.length  - 1}">
      <div 
        class="w-100 policy-name-container cursor-pointer" 
        data-bs-toggle="collapse" 
        [attr.data-bs-target]="'#collapse_' + i"
      >
        <h3>{{i + 1}}. {{item.title}}</h3>
      </div>
      <div class="collapse" [id]="'collapse_' + i">
        <div class="px-5 py-3" *ngIf="item.description">
          {{item.description}}
        </div>
        <div [innerHTML]="item.html" *ngIf="item.html" class="px-5 py-3">

        </div>
      </div>
    </div>
  </div>
</section>